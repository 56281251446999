/* Place your extensions here to make migration to a new release easier. */
.custom-loader {
  .http-loader {
    display: block !important;
  }
}
.http-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.http-loader:after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #0d6efd;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
.mt-10px {
  margin-top: 10px;
}
.ml-10px {
  margin-left: 10px;
}
.c-p {
  cursor: pointer;
}
.sm-drop-down {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.close {
  border: none;
}
/* .modal-body{
  .sm{
    height: 2.5rem !important;
  }
} */

.sec-li {
  font-size: 1rem;
}
.title {
  font-weight: 400;
}
.font-22 {
  font-size: 40px !important;
}
.white {
  .nav-link {
    color: #fff !important;
  }
}

.d-ma {
  width: 300px;
}
.p-title {
  font-size: 1.7rem !important;
  font-weight: 500 !important;
}

// Global app css

.primary-font-color {
  color: #0a58ca;
}

// .site-header a{
//   z-index: 999;
// }

#particles-js {
  z-index: -2;
}
